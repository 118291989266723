import React from 'react'
import styled from 'styled-components'

import Mastodon from './icons/Mastodon.jsx'
import Twitter from './icons/Twitter.jsx'
import Rss from './icons/Rss.jsx'
import Spotify from './icons/Spotify.jsx'
import Podcast from './icons/Podcast.jsx'

const Footer = styled.footer`
  & {
    position: absolute;
    display: flex;
    height: 50px;
    line-height: 50px;
    bottom: 0;
    width: calc(100% - 1.25rem);
    justify-content: center;
    padding-bottom: 50px;
  }

  & > a:not(:first-child) {
    margin-left: 1.5em;
  }

  & > a {
    color: rgb(229, 233, 215);
  }
`

export default () => (
  <Footer>
    <a href="/rss.xml" target="_blank" rel="noopener noreferrer">
      <Rss />
    </a>
    <a
      href="https://mamot.fr/@radiote"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Mastodon />
    </a>
    <a
      href="https://twitter.com/radiotepodcast"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Twitter />
    </a>
    <a
      href="https://open.spotify.com/show/1ybWjoW4wycVVut8Xc6Mys?si=jfr8zXnCRg6dPDzdNYw4eA"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Spotify />
    </a>
    <a
      href="https://itunes.apple.com/ch/podcast/radiote/id1454628667?l=fr"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Podcast />
    </a>
  </Footer>
)
