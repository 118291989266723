import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Link } from 'gatsby'

import { rhythm, scale } from '../utils/typography'
import Footer from './Footer.jsx'

const mainColor = '#5A9A54'

const GlobalStyle = createGlobalStyle`
  a {
    color: ${mainColor};
    background-image: none;
  }
  a:hover {
    color: ${mainColor};
    text-decoration: none;
  }
`

const Wrapper = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  padding-bottom: 50px;

  max-width: ${rhythm(24)};
  padding: ${rhythm(1.5)} ${rhythm(3 / 4)};
`

const Description = styled.h4`
  margin-top: ${rhythm(-1.5)};
  color: ${mainColor};
`

const Title = styled.h1`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${rhythm(1.5)};
  margin-top: 0;
  text-transform: uppercase;
  font-family: 'Concert One';
`

const SmallTitle = styled.h3`
  color: ${mainColor};
  margin-bottom: ${rhythm(-1)};
  margin-top: 0;
  text-transform: uppercase;
  font-family: 'Concert One';
`

const StyledLink = styled(Link)`
  box-shadow: none;
  text-decoration: none;
  color: inherit;
`

class Layout extends React.Component {
  render() {
    const { location, title, description, data, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <>
          <Title style={{ ...scale(1.5) }}>
            <StyledLink to={`/`}>{title}</StyledLink>
          </Title>
          <Description>{description}</Description>
        </>
      )
    } else {
      header = (
        <SmallTitle>
          <StyledLink to={`/`}>{title}</StyledLink>
        </SmallTitle>
      )
    }
    return (
      <Wrapper>
        {header}
        {children}
        <Footer />
        <GlobalStyle />
      </Wrapper>
    )
  }
}

export default Layout
