import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import styled, { keyframes } from 'styled-components/macro'

import Pause from './icons/Pause.jsx'
import Play from './icons/Play.jsx'
import BaseLoading from './icons/Loading.jsx'

import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

const rotating = keyframes`
  from {
      transform: rotate(0deg);
  } to {
      transform: rotate(360deg);
  }
`

const Loading = styled(BaseLoading)`
  animation: ${rotating} 2s linear infinite;
`

const useVolumeState = audioRef => {
  const [volume, setVolume] = useState(null)
  const [muted, setMute] = useState(null)

  const changeVolume = volume => {
    audioRef.current.muted = !volume
    audioRef.current.volume = volume
  }

  const toggleMute = () => {
    audioRef.current.muted = !audioRef.current.muted
  }

  useEffect(() => {
    if (!audioRef.current) return

    const updateVolume = () => {
      setVolume(audioRef.current.volume)
      setMute(audioRef.current.muted)
    }

    if (!volume) updateVolume()
    audioRef.current.addEventListener('volumechange', updateVolume)

    return () =>
      audioRef.current.removeEventListener('volumechange', updateVolume)
  })

  return [volume, changeVolume, muted, toggleMute]
}

const useAudioState = audioRef => {
  const [duration, setDuration] = useState(1)
  const [progress, setProgress] = useState(0)
  const [isPaused, setIsPaused] = useState(null)
  const [isReady, setIsReady] = useState(false)

  const togglePlay = () => {
    if (audioRef.current.paused) {
      audioRef.current.play()
    } else {
      audioRef.current.pause()
    }
  }

  const seekTo = position => {
    audioRef.current.currentTime = position
  }

  useEffect(
    () => {
      if (!audioRef.current) return
      const updatePause = () => {
        setIsPaused(audioRef.current.paused)
        setIsReady(audioRef.current.readyState > 2)
      }
      updatePause()

      const onSeeking = () => setCurrentStatus('seeking')
      const onEnded = () => setCurrentStatus('ended')
      const onProgress = () => setProgress(audioRef.current.currentTime)
      const changeDuration = () => setDuration(audioRef.current.duration)

      if (!progress) onProgress()
      if (!duration) changeDuration()

      audioRef.current.addEventListener('readystatechange', updatePause)
      audioRef.current.addEventListener('playing', updatePause)
      audioRef.current.addEventListener('pause', updatePause)
      audioRef.current.addEventListener('timeupdate', onProgress)
      audioRef.current.addEventListener('durationchange', changeDuration)

      return () => {
        audioRef.current.removeEventListener('readystatechange', updatePause)
        audioRef.current.removeEventListener('playing', updatePause)
        audioRef.current.removeEventListener('pause', updatePause)
        audioRef.current.removeEventListener('timeupdate', onProgress)
        audioRef.current.removeEventListener('durationchange', changeDuration)
      }
    },
    [!audioRef.current]
  )

  return [
    {
      progress,
      duration,
      isPaused,
      isReady,
    },
    togglePlay,
    seekTo,
  ]
}

const _f0 = number => {
  if (number < 10) return `0${number}`
  return `${number}`
}
const formattedHours = seconds => {
  const hours = Math.floor(seconds / 3600)
  let reminder = seconds - hours * 3600
  const minutes = Math.floor(reminder / 60)
  const outSeconds = Math.round(reminder - minutes * 60)

  const formattedMinutes = `${_f0(minutes)}:${_f0(outSeconds)}`
  if (!hours) return formattedMinutes
  return `${_f0(hours)}:${formattedMinutes}`
}

const Scrubber = ({ value, maxValue, step, onChange }) => {
  const [capturing, setCapturing] = useState(false)
  const [shownValue, setShownValue] = useState(value)

  useEffect(
    () => {
      if (!capturing) setShownValue(value)
    },
    [value]
  )

  const startCapturing = ev => {
    setCapturing(true)
  }
  const stopCapturing = ev => {
    onChange(shownValue)
    setCapturing(false)
  }

  return (
    <ScrubberStyle>
      <Slider
        value={shownValue}
        max={maxValue}
        step={step}
        onBeforeChange={startCapturing}
        onAfterChange={stopCapturing}
        onChange={setShownValue}
        handleStyle={{
          borderStyle: 'none',
          height: '16px',
          width: '16px',
          borderRadius: '8px',
          marginTop: '-6px',
          background: '#222',
        }}
        railStyle={{
          background: '#666',
        }}
        trackStyle={{
          background: '#222',
        }}
      />

      <TimeLimits>
        <TimeStyle>{formattedHours(shownValue)}</TimeStyle>
        <TimeStyle>-{formattedHours(maxValue - shownValue)}</TimeStyle>
      </TimeLimits>
    </ScrubberStyle>
  )
}

const AudioControls = styled.div`
  display: flex;
  background-color: hsl(200, 12%, 95%);
  padding: 0.5em 1em;
  border-radius: 2px;
  align-items: center;
`

const ScrubberStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & .rc-slider-handle:focus {
    box-shadow: 0;
    border-style: none;
  }

  & .rc-slider-handle:active {
    box-shadow: 0 0 5px #666;
    border-style: none;
  }
`

const TimeLimits = styled.div`
  display: flex;
  justify-content: space-between;
`

const TimeStyle = styled.span`
  font-size: 0.8em;
  line-height: 1em;
  user-select: none;
`

const ControlButton = styled.button`
  background: none;
  color: inherit;
  fill: #333;
  &:hover {
    fill: #444;
  }
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0em;
  margin-right: 0.5em;
`

const ControlInfo = styled.span`
  margin-top: -3px;
  font-size: 0.4em;
`

export const ProgressBar = styled(({ pos = 0, ...props }) => (
  <svg viewBox="0 0 100 2" {...props}>
    <path d="M 0,2 L 100,2" stroke="#aaa" strokeWidth="1" fillOpacity="0" />
    <path
      d="M 0,2 L 100,2"
      stroke="#222"
      strokeWidth="2"
      fillOpacity="0"
      style={{ strokeDasharray: '100, 100', strokeDashoffset: 100 - pos }}
    />
  </svg>
))`
  margin-top: 5px;
`

export const SmallPlayer = ({ src, ...props }) => {
  const audioRef = useRef(null)
  const [audioState, togglePlay] = useAudioState(audioRef)
  const { isPaused, isReady } = audioState

  let Control = Play
  let canToggle = true
  if (isReady && !isPaused) Control = Pause
  if (!isReady && !isPaused) {
    Control = Loading
    canToggle = false
  }

  return (
    <React.Fragment>
      <ControlButton onClick={canToggle ? togglePlay : undefined} {...props}>
        <Control />
        <ProgressBar
          height="2px"
          width="1em"
          pos={(audioState.progress / audioState.duration) * 100}
        />
      </ControlButton>
      <audio ref={audioRef} src={src}>
        <p>
          Your browser does not support the <code>audio</code> element.
        </p>
      </audio>
    </React.Fragment>
  )
}

export default ({ src, ...props }) => {
  const audioRef = useRef(null)
  const [audioState, togglePlay, seekTo] = useAudioState(audioRef)
  const { isPaused, isReady } = audioState

  let Control = Play
  let canToggle = true
  if (isReady && !isPaused) Control = Pause
  if (!isReady && !isPaused) {
    Control = Loading
    canToggle = false
  }

  return (
    <React.Fragment>
      <AudioControls {...props}>
        <ControlButton onClick={canToggle ? togglePlay : undefined}>
          <Control />
        </ControlButton>
        <Scrubber
          value={audioState.progress}
          maxValue={audioState.duration}
          step={audioState.duration / 1000}
          onChange={seekTo}
        />
      </AudioControls>
      <audio src={src} ref={audioRef}>
        <p>
          Your browser does not support the <code>audio</code> element.
        </p>
      </audio>
    </React.Fragment>
  )
}
