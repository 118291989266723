import React from 'react'

export default ({ size = '1em', ...props }) => (
  <svg height={size} viewBox="0 0 1024 1024" {...props}>
    <path d="M527 245.6V183 83.3 60.5c0-7.8-6.9-15.4-15-15-8.1.4-15 6.6-15 15V245.6c0 7.8 6.9 15.4 15 15 8.1-.4 15-6.6 15-15z" />
    <path d="M527 963.5v-62.6-99.7-22.8c0-7.8-6.9-15.4-15-15-8.1.4-15 6.6-15 15V963.5c0 7.8 6.9 15.4 15 15 8.1-.4 15-6.6 15-15z" />
    <path d="M527 245.6V183 83.3 60.5c0-7.8-6.9-15.4-15-15-8.1.4-15 6.6-15 15V245.6c0 7.8 6.9 15.4 15 15 8.1-.4 15-6.6 15-15z" />
    <path d="M527 963.5v-62.6-99.7-22.8c0-7.8-6.9-15.4-15-15-8.1.4-15 6.6-15 15V963.5c0 7.8 6.9 15.4 15 15 8.1-.4 15-6.6 15-15z" />
    <g>
      <path d="M240.1 497H51.3c-7.8 0-15.4 6.9-15 15 .4 8.1 6.6 15 15 15h188.8c7.8 0 15.4-6.9 15-15-.3-8.1-6.6-15-15-15z" />
      <path d="M972.7 497H783.9c-7.8 0-15.4 6.9-15 15 .4 8.1 6.6 15 15 15h188.8c7.8 0 15.4-6.9 15-15-.3-8.1-6.6-15-15-15z" />
      <path d="M240.1 497H51.3c-7.8 0-15.4 6.9-15 15 .4 8.1 6.6 15 15 15h188.8c7.8 0 15.4-6.9 15-15-.3-8.1-6.6-15-15-15z" />
      <g>
        <path d="M972.7 497H783.9c-7.8 0-15.4 6.9-15 15 .4 8.1 6.6 15 15 15h188.8c7.8 0 15.4-6.9 15-15-.3-8.1-6.6-15-15-15z" />
      </g>
    </g>
    <g>
      <path d="M718.2 337.5c15.3-14.5 30.5-28.9 45.8-43.4 24.4-23.1 48.7-46.1 73.1-69.2 5.6-5.3 11.3-10.7 16.9-16 5.9-5.6 5.6-15.6 0-21.2-5.9-5.9-15.3-5.6-21.2 0-15.3 14.5-30.5 28.9-45.8 43.4-24.4 23.1-48.7 46.1-73.1 69.2-5.6 5.3-11.3 10.7-16.9 16-5.9 5.6-5.6 15.6 0 21.2 5.8 5.9 15.3 5.6 21.2 0z" />
      <path d="M191.2 836.3c15.3-14.5 30.5-28.9 45.8-43.4 24.4-23.1 48.7-46.1 73.1-69.2 5.6-5.3 11.3-10.7 16.9-16 5.9-5.6 5.6-15.6 0-21.2-5.9-5.9-15.3-5.6-21.2 0-15.3 14.5-30.5 28.9-45.8 43.4-24.4 23.1-48.7 46.1-73.1 69.2-5.6 5.3-11.3 10.7-16.9 16-5.9 5.6-5.6 15.6 0 21.2 5.9 5.9 15.3 5.6 21.2 0z" />
      <path d="M718.2 337.5c15.3-14.5 30.5-28.9 45.8-43.4 24.4-23.1 48.7-46.1 73.1-69.2 5.6-5.3 11.3-10.7 16.9-16 5.9-5.6 5.6-15.6 0-21.2-5.9-5.9-15.3-5.6-21.2 0-15.3 14.5-30.5 28.9-45.8 43.4-24.4 23.1-48.7 46.1-73.1 69.2-5.6 5.3-11.3 10.7-16.9 16-5.9 5.6-5.6 15.6 0 21.2 5.8 5.9 15.3 5.6 21.2 0z" />
      <g>
        <path d="M191.2 836.3c15.3-14.5 30.5-28.9 45.8-43.4 24.4-23.1 48.7-46.1 73.1-69.2 5.6-5.3 11.3-10.7 16.9-16 5.9-5.6 5.6-15.6 0-21.2-5.9-5.9-15.3-5.6-21.2 0-15.3 14.5-30.5 28.9-45.8 43.4-24.4 23.1-48.7 46.1-73.1 69.2-5.6 5.3-11.3 10.7-16.9 16-5.9 5.6-5.6 15.6 0 21.2 5.9 5.9 15.3 5.6 21.2 0z" />
      </g>
      <g>
        <path d="M333.7 309.7c-14.7-15-29.5-29.9-44.2-44.9-23.5-23.9-47.1-47.8-70.6-71.6l-16.3-16.6c-5.7-5.8-15.5-5.7-21.2 0-5.8 5.8-5.7 15.4 0 21.2 14.7 15 29.5 29.9 44.2 44.9 23.5 23.9 47.1 47.8 70.6 71.6l16.3 16.6c5.7 5.8 15.5 5.7 21.2 0 5.8-5.7 5.7-15.4 0-21.2z" />
        <path d="M842.7 826.2c-14.7-15-29.5-29.9-44.2-44.9-23.5-23.9-47.1-47.8-70.6-71.6l-16.3-16.6c-5.7-5.8-15.5-5.7-21.2 0-5.8 5.8-5.7 15.4 0 21.2 14.7 15 29.5 29.9 44.2 44.9 23.5 23.9 47.1 47.8 70.6 71.6l16.3 16.6c5.7 5.8 15.5 5.7 21.2 0 5.8-5.8 5.7-15.5 0-21.2z" />
        <path d="M333.7 309.7c-14.7-15-29.5-29.9-44.2-44.9-23.5-23.9-47.1-47.8-70.6-71.6l-16.3-16.6c-5.7-5.8-15.5-5.7-21.2 0-5.8 5.8-5.7 15.4 0 21.2 14.7 15 29.5 29.9 44.2 44.9 23.5 23.9 47.1 47.8 70.6 71.6l16.3 16.6c5.7 5.8 15.5 5.7 21.2 0 5.8-5.7 5.7-15.4 0-21.2z" />
        <g>
          <path d="M842.7 826.2c-14.7-15-29.5-29.9-44.2-44.9-23.5-23.9-47.1-47.8-70.6-71.6l-16.3-16.6c-5.7-5.8-15.5-5.7-21.2 0-5.8 5.8-5.7 15.4 0 21.2 14.7 15 29.5 29.9 44.2 44.9 23.5 23.9 47.1 47.8 70.6 71.6l16.3 16.6c5.7 5.8 15.5 5.7 21.2 0 5.8-5.8 5.7-15.5 0-21.2z" />
        </g>
      </g>
    </g>
    <g>
      <path d="M766.9 428.7c19.6-7.6 39.2-15.3 58.8-22.9 31.3-12.2 62.5-24.4 93.8-36.6 7.2-2.8 14.5-5.6 21.7-8.5 7.5-2.9 12.8-10.1 10.5-18.5-2-7.2-10.9-13.4-18.5-10.5-19.6 7.6-39.2 15.3-58.8 22.9-31.3 12.2-62.5 24.4-93.8 36.6-7.2 2.8-14.5 5.6-21.7 8.5-7.5 2.9-12.8 10.1-10.5 18.5 2 7.2 10.9 13.4 18.5 10.5z" />
      <path d="M90.8 692.2c19.6-7.6 39.2-15.3 58.8-22.9 31.3-12.2 62.5-24.4 93.8-36.6 7.2-2.8 14.5-5.6 21.7-8.5 7.5-2.9 12.8-10.1 10.5-18.5-2-7.2-10.9-13.4-18.5-10.5-19.6 7.6-39.2 15.3-58.8 22.9-31.3 12.2-62.5 24.4-93.8 36.6-7.2 2.8-14.5 5.6-21.7 8.5-7.5 2.9-12.8 10.1-10.5 18.5 2.1 7.2 10.9 13.4 18.5 10.5z" />
      <path d="M766.9 428.7c19.6-7.6 39.2-15.3 58.8-22.9 31.3-12.2 62.5-24.4 93.8-36.6 7.2-2.8 14.5-5.6 21.7-8.5 7.5-2.9 12.8-10.1 10.5-18.5-2-7.2-10.9-13.4-18.5-10.5-19.6 7.6-39.2 15.3-58.8 22.9-31.3 12.2-62.5 24.4-93.8 36.6-7.2 2.8-14.5 5.6-21.7 8.5-7.5 2.9-12.8 10.1-10.5 18.5 2 7.2 10.9 13.4 18.5 10.5z" />
      <g>
        <path d="M90.8 692.2c19.6-7.6 39.2-15.3 58.8-22.9 31.3-12.2 62.5-24.4 93.8-36.6 7.2-2.8 14.5-5.6 21.7-8.5 7.5-2.9 12.8-10.1 10.5-18.5-2-7.2-10.9-13.4-18.5-10.5-19.6 7.6-39.2 15.3-58.8 22.9-31.3 12.2-62.5 24.4-93.8 36.6-7.2 2.8-14.5 5.6-21.7 8.5-7.5 2.9-12.8 10.1-10.5 18.5 2.1 7.2 10.9 13.4 18.5 10.5z" />
      </g>
      <g>
        <path d="M423.7 259.3c-8-19.4-16-38.8-24.1-58.2l-38.4-93c-3-7.2-5.9-14.3-8.9-21.5-3.1-7.5-10-12.8-18.5-10.5-7.1 1.9-13.6 10.9-10.5 18.5 8 19.4 16 38.8 24.1 58.2l38.4 93c3 7.2 5.9 14.3 8.9 21.5 3.1 7.5 10 12.8 18.5 10.5 7.1-2 13.6-10.9 10.5-18.5z" />
        <path d="M700.6 929.4c-8-19.4-16-38.8-24.1-58.2l-38.4-93c-3-7.2-5.9-14.3-8.9-21.5-3.1-7.5-10-12.8-18.5-10.5-7.1 1.9-13.6 10.9-10.5 18.5 8 19.4 16 38.8 24.1 58.2l38.4 93c3 7.2 5.9 14.3 8.9 21.5 3.1 7.5 10 12.8 18.5 10.5 7.1-2 13.6-10.9 10.5-18.5z" />
        <path d="M423.7 259.3c-8-19.4-16-38.8-24.1-58.2l-38.4-93c-3-7.2-5.9-14.3-8.9-21.5-3.1-7.5-10-12.8-18.5-10.5-7.1 1.9-13.6 10.9-10.5 18.5 8 19.4 16 38.8 24.1 58.2l38.4 93c3 7.2 5.9 14.3 8.9 21.5 3.1 7.5 10 12.8 18.5 10.5 7.1-2 13.6-10.9 10.5-18.5z" />
        <g>
          <path d="M700.6 929.4c-8-19.4-16-38.8-24.1-58.2l-38.4-93c-3-7.2-5.9-14.3-8.9-21.5-3.1-7.5-10-12.8-18.5-10.5-7.1 1.9-13.6 10.9-10.5 18.5 8 19.4 16 38.8 24.1 58.2l38.4 93c3 7.2 5.9 14.3 8.9 21.5 3.1 7.5 10 12.8 18.5 10.5 7.1-2 13.6-10.9 10.5-18.5z" />
        </g>
      </g>
    </g>
    <g>
      <path d="M756.9 629.3c19.4 8 38.9 16.1 58.3 24.1l93 38.4c7.2 3 14.4 5.9 21.5 8.9 7.3 3 16.8-3.2 18.5-10.5 2-8.7-2.7-15.2-10.5-18.5-19.4-8-38.9-16.1-58.3-24.1l-93-38.4c-7.2-3-14.4-5.9-21.5-8.9-7.3-3-16.8 3.2-18.5 10.5-2 8.8 2.7 15.3 10.5 18.5z" />
      <path d="M86.3 352.2c19.4 8 38.9 16.1 58.3 24.1l93 38.4c7.2 3 14.4 5.9 21.5 8.9 7.3 3 16.8-3.2 18.5-10.5 2-8.7-2.7-15.2-10.5-18.5-19.4-8-38.9-16.1-58.3-24.1l-93-38.4c-7.2-3-14.4-5.9-21.5-8.9-7.3-3-16.8 3.2-18.5 10.5-2 8.8 2.7 15.3 10.5 18.5z" />
      <path d="M756.9 629.3c19.4 8 38.9 16.1 58.3 24.1l93 38.4c7.2 3 14.4 5.9 21.5 8.9 7.3 3 16.8-3.2 18.5-10.5 2-8.7-2.7-15.2-10.5-18.5-19.4-8-38.9-16.1-58.3-24.1l-93-38.4c-7.2-3-14.4-5.9-21.5-8.9-7.3-3-16.8 3.2-18.5 10.5-2 8.8 2.7 15.3 10.5 18.5z" />
      <g>
        <path d="M86.3 352.2c19.4 8 38.9 16.1 58.3 24.1l93 38.4c7.2 3 14.4 5.9 21.5 8.9 7.3 3 16.8-3.2 18.5-10.5 2-8.7-2.7-15.2-10.5-18.5-19.4-8-38.9-16.1-58.3-24.1l-93-38.4c-7.2-3-14.4-5.9-21.5-8.9-7.3-3-16.8 3.2-18.5 10.5-2 8.8 2.7 15.3 10.5 18.5z" />
      </g>
      <g>
        <path d="M624.2 265.3c7.6-19.6 15.3-39.1 22.9-58.7 12.2-31.2 24.3-62.5 36.5-93.7 2.8-7.2 5.6-14.5 8.5-21.7 2.9-7.3-3.1-16.8-10.5-18.5-8.6-2-15.4 2.6-18.5 10.5-7.6 19.6-15.3 39.1-22.9 58.7-12.2 31.2-24.3 62.5-36.5 93.7-2.8 7.2-5.6 14.5-8.5 21.7-2.9 7.3 3.1 16.8 10.5 18.5 8.6 1.9 15.4-2.7 18.5-10.5z" />
        <path d="M360.9 940.8c7.6-19.6 15.3-39.1 22.9-58.7 12.2-31.2 24.3-62.5 36.5-93.7 2.8-7.2 5.6-14.5 8.5-21.7 2.9-7.3-3.1-16.8-10.5-18.5-8.6-2-15.4 2.6-18.5 10.5-7.6 19.6-15.3 39.1-22.9 58.7-12.2 31.2-24.3 62.5-36.5 93.7-2.8 7.2-5.6 14.5-8.5 21.7-2.9 7.3 3.1 16.8 10.5 18.5 8.7 2 15.4-2.6 18.5-10.5z" />
        <path d="M624.2 265.3c7.6-19.6 15.3-39.1 22.9-58.7 12.2-31.2 24.3-62.5 36.5-93.7 2.8-7.2 5.6-14.5 8.5-21.7 2.9-7.3-3.1-16.8-10.5-18.5-8.6-2-15.4 2.6-18.5 10.5-7.6 19.6-15.3 39.1-22.9 58.7-12.2 31.2-24.3 62.5-36.5 93.7-2.8 7.2-5.6 14.5-8.5 21.7-2.9 7.3 3.1 16.8 10.5 18.5 8.6 1.9 15.4-2.7 18.5-10.5z" />
        <g>
          <path d="M360.9 940.8c7.6-19.6 15.3-39.1 22.9-58.7 12.2-31.2 24.3-62.5 36.5-93.7 2.8-7.2 5.6-14.5 8.5-21.7 2.9-7.3-3.1-16.8-10.5-18.5-8.6-2-15.4 2.6-18.5 10.5-7.6 19.6-15.3 39.1-22.9 58.7-12.2 31.2-24.3 62.5-36.5 93.7-2.8 7.2-5.6 14.5-8.5 21.7-2.9 7.3 3.1 16.8 10.5 18.5 8.7 2 15.4-2.6 18.5-10.5z" />
        </g>
      </g>
    </g>
  </svg>
)
